import { Component, OnInit } from '@angular/core';
import { merge, Observable, Subject, of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { switchMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  projectObservable: Observable<any>;
  refresh = new Subject();
  dataPassed: boolean = false;
  projects: any;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.projectObservable = merge(of(null), this.refresh).pipe(
      switchMap(() => {
        return this.dataService.getAllProjects();
      }),
      tap((res) => {
        this.projects = res;
        this.projects.map((item) => {
          if (item?.server?.state) {
            switch (item.server.state) {
              case 0:
                item['orderState'] = 6;
                item['server_status'] = "Pending";
                break;
              case 1:
                item['orderState'] = 3;
                item['server_status'] = "Creating";
                break;
              case 2:
                item['orderState'] = 1;
                item['server_status'] = "Ready";
                break;
              case 3:
                item['orderState'] = 0;
                item['server_status'] = "Unready";
                break;
              case 4:
                item['orderState'] = 4;
                item['server_status'] = "TerminationRequested";
                break;
              case 5:
                item['orderState'] = 5;
                item['server_status'] = "Terminating";
                break;
              case 6:
                item['orderState'] = 2;
                item['server_status'] = "Terminated";
                break;
            }
          }

          if (item?.expire_at) {
            if (new Date(item.expire_at).getTime() - new Date().getTime() <= 0) {
              item['expire_status'] = 'off';
            } else {
              let diffDate = Math.floor(
                (new Date(item.expire_at).getTime() - new Date().getTime()) /
                  (1000 * 60 * 60 * 24)
              );
              if (diffDate <= 30) item['expire_status'] = 'less_month';
              if (diffDate <= 7) item['expire_status'] = 'less_week';
              if (diffDate <= 3) item['expire_status'] = 'less_day';
            }
          }
        });
        this.projects = _.sortBy(this.projects, 'orderState');
        this.dataPassed = true;
      })
    );
  }
  refreshData() {
    this.dataPassed = false;
    this.ngOnInit();
  }
}
