import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable, merge, of, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DataService } from "../../services/data.service";
import { UserConfirmationDialog } from 'src/app/popups/user-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  userObservable: Observable<any>;
  refresh = new Subject();
  dataPassed: boolean = false;

  constructor(
    private dataService: DataService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private router:Router
    ) { }

  ngOnInit() {


    this.userObservable = merge(of(null), this.refresh).pipe(
      switchMap(() => {
        return this.dataService.getAllUsers();
      }),
      tap(() => {
        this.dataPassed = true;
      })
    );

  }

  openDialog(user_id): void {
    this.dialog
      .open(UserConfirmationDialog, {
        width: "30%",
        maxWidth: "400px",
      })
      .afterClosed()
      .toPromise()
      .then((data) => {
        if (data.result) {
          this.dataPassed = false;
          this.dataService
            .getUserByUserId(data.usid)
            .toPromise()
            .then((res) => {
              if (res[0]) {
                if (res[0]._id == user_id) { this.router.navigate([`/details/${data.usid}`]); }
                else {
                  this.toastr.error(undefined, "This Is Not The User's ID", { positionClass: "toast-bottom-center", timeOut: 2500 })
                }
              } else {
                this.toastr.error(undefined, "Wrong User-ID", { positionClass: "toast-bottom-center", timeOut: 2500 })
              }
              this.refresh.next();
            });
        }
      });
  }

}
