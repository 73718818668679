import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DataService } from "../services/data.service";

@Component({
  selector: "./user-confirmation",
  templateUrl: "../popups/user-confirmation.html"
})
export class UserConfirmationDialog {
  usid: string;

  constructor(
    public dialogRef: MatDialogRef<UserConfirmationDialog>,
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onOkClick() {
    this.dialogRef.close({result: true, usid: this.usid});
  }
}