
<div class="content-area">
  <div class="container">

    <div class="row additional-info mt-5">
      <div class="col-12">
        <mat-card class="col-12">
          <mat-card-title>
           <h2 class="w-100 text-center"> See All Informations Of User</h2>
          </mat-card-title>
          <mat-card-content>
            <form class="example-form">
              <mat-form-field class="w-100 text-center">
                <input [(ngModel)]="user_id" name="user_id" matInput  type="text">
              </mat-form-field>
              </form>
          <div mat-button class="col-12 button-container">
            <button mat-button [routerLink]="['/projects']">Confirm</button>
          </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
</div>
