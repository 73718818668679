import { Organization } from './../../interfaces/organization';
import { Invitation } from './../../interfaces/invitation';
import { User } from './../../interfaces/user';
import { ActivatedRoute } from '@angular/router';
import { Observable, merge, Subject, of, forkJoin } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { withLatestFrom, switchMap, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { License } from 'src/app/interfaces/license';

@Component({
  selector: 'app-user-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  userDetailsObservable: Observable<any>;
  refresh = new Subject();
  user: any;
  dataPassed: boolean = false;
  organizations: Organization[];
  projects: License[];
  invitations: Invitation[];
  constructor(
    private dataService: DataService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userDetailsObservable = merge(this.route.params, this.refresh).pipe(
      withLatestFrom(this.route.params),
      switchMap(([_, params]) => {
        return this.dataService.getUserByUserId(params.userId);
      }),
      switchMap((user) => {
        this.user = user[0];
        return this.dataService.getUserInvitations(this.user.email);
      }),
      switchMap((invitation: Invitation[]) => {
        this.invitations = invitation;
        return this.dataService.getUserOrganizations(this.user._id);
      }),
      switchMap((org: Organization[]) => {
        this.organizations = org;
        return this.dataService.getUserProjects(this.user._id);
      }),
      tap((result: License[]) => {
        this.projects = result;
        this.projects.map((item) => {
          item.server.state == 0 ? (item.server['status'] = 'Pending') : '';
          item.server.state == 1 ? (item.server['status'] = 'Creating') : '';
          item.server.state == 2 ? (item.server['status'] = 'Ready') : '';
          item.server.state == 3 ? (item.server['status'] = 'Unready') : '';
          item.server.state == 4
            ? (item.server['status'] = 'TerminationRequested')
            : '';
          item.server.state == 5 ? (item.server['status'] = 'Terminating') : '';
          item.server.state == 6 ? (item.server['status'] = 'Terminated ') : '';
        });

        console.log('projects ', this.projects);
        console.log('inv', this.invitations);
        console.log('org', this.organizations);
        console.log('user', this.user);

        this.dataPassed = true;
      })
    );
  }
}
