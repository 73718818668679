import { UserDetailsComponent } from './pages/user-details/details.component';
import { UsersComponent } from './pages/users/users.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';

export const loginState = true;

const routes: Routes = [
  { path: "", component: ProjectsComponent, data: { needSession: true } },
  { path: "projects", component: ProjectsComponent, data: { needSession: true } },
  { path: "users", component: UsersComponent, data: { needSession: true } },
  { path: "login", component: LoginComponent, data: {needSession: false}},
  { path: "home", component: HomeComponent, data: {needSession: false}},
  { path: "details/:userId", component: UserDetailsComponent, data: {needSession: true} },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
