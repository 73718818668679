import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthResult, JWT_AUTH } from '../interfaces/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(email: string, password: string) {
    return this.http
      .post<AuthResult>(
        `${environment.url}fn-execute/adminLogin`,
        {
          email,
          password,
        },
        {
          withCredentials: true,
        }
      )
      .pipe(
        tap((res) => {
          if (res && res.jwt_auth && res.jwt_auth.jwt_token) {
            this.setSession({
              jwt_token: res.jwt_auth.jwt_token,
            });
          } else {
            return;
          }
        })
      );
  }

  private setSession(authResult: JWT_AUTH) {
    let date = new Date();
    date.setHours(date.getHours() + (24 * 2));
    localStorage.setItem('token', authResult.jwt_token);
    localStorage.setItem('expire', date.toISOString());
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('expire');
  }

  getToken() {
    return localStorage.getItem('token');
  }
}
