<div class="content-area">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="page-title">
                    Projects
                    <button mat-icon-button (click)="refreshData()">
                        <mat-icon class="refresh-project" fontSet="material-icons-outlined">refresh</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <loading-spinner *ngIf="!dataPassed"></loading-spinner>
        <div *ngIf="projectObservable | async; let projectDataSource">
            <div class="color-info">
             <div class="explain font-small">   Expire Date Color Info :</div>
                <div>
                    <span class="expire_less_month small-circle circle"></span>
                    <span class="font-small"> Less then a month</span>
                </div>
                <div>
                    <span class="expire_less_week small-circle circle"></span>
                    <span class="font-small"> Less then a week</span>
                </div>
                <div> <span class="expire_less_day small-circle circle"></span>
                    <span class="font-small"> Less then 3 day</span>
                </div>
                <div> <span class="expire_off small-circle circle"></span>
                    <span class="font-small">Off</span>
                </div>
            </div>
            <div *ngIf="projectDataSource.length == 0 && dataPassed" class="text-center col-12">
                Users Bucket is empty.
            </div>

            <table *ngIf="projectDataSource.length > 0 && dataPassed" mat-table class="w-100" [dataSource]="projects">

                <ng-container matColumnDef="project_name">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                        Project Name
                    </th>
                    <td class="text-center" mat-cell *matCellDef="let project">
                        <a [href]="['https://'+project?.server?.ip_address]" target="_blank"> {{ project?.project_name
                            }}</a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="package_code">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                        Package Code
                    </th>
                    <td class="text-center" mat-cell *matCellDef="let project">
                        {{ project?.package_code }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="expire_at">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                        Expire Date
                    </th>
                    <td class="text-center circle-state" mat-cell *matCellDef="let project">
                        <span class="circle" [class]="'expire_'+project?.expire_status"></span>
                        {{ project?.expire_at | date:'dd.MM.yyyy'
                        }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="user_name">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                        User
                    </th>
                    <td class="text-center" mat-cell *matCellDef="let project">
                        {{ project?.user?.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="version">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                        Version
                    </th>
                    <td class="text-center" mat-cell *matCellDef="let project">
                        {{ project?.server?.version }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                        Server Status
                    </th>
                    <td class="text-center project-state" mat-cell *matCellDef="let project">
                        <div [class]="'project-state-'+project?.server?.state">
                            <span class="circle"></span>
                            {{project.server_status}}
                        </div>
                    </td>
                </ng-container>

                <tr class="text-center" mat-header-row *matHeaderRowDef="[
            'project_name',
            'package_code',
            'expire_at',
            'user_name',
            'version',
            'status'

          ]"></tr>
                <tr class="text-center" mat-row *matRowDef="
            let row;
            columns: [
            'project_name',
            'package_code',
            'expire_at',
            'user_name',
            'version',
            'status'
            ]
          "></tr>
            </table>

        </div>
    </div>
</div>
<!-- [class.make-green]="row.status=='active'" [class.make-coral]="row.status=='denied'"
                    [class.make-gray]="row.status=='revoked'"-->