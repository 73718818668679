import { Component, OnInit, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Router, RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'hq-panel';
  public innerWidth: number;
  loginState = false;
  activeUrl: string;
  routeData = new Subject();

  constructor(
    private authService: AuthService,
    public router: Router,
    private titleService: Title
  ) { }

  ngOnInit() {

    //this.activeUrl = this.activatedRoute.snapshot;

    this.router.events.subscribe(data => {
      this.activeUrl = this.router.url;
      if (data instanceof RoutesRecognized) {
        this.routeData.next(data);
      }
    });
    this.routeData.subscribe((data: any) => {
      if (
        new Date() > new Date(localStorage.getItem("expire")) ||
        !localStorage.getItem("token")
      ) {
        this.authService.logout();
        this.loginState = false;
        if (data.state.root.firstChild.data.needSession) {
          this.router.navigate(["/login"]);
        }
      } else {
        this.loginState = true;
      }
      if (data["url"]) {
        let title =
          data["url"] != "/"
            ? data["url"]
              .substring(1, data["url"].length)
              .charAt(0)
              .toUpperCase() +
            data["url"].substring(1, data["url"].length).substring(1)
            : "Spica";
        this.titleService.setTitle("Head Quarters | " + title);
      }
    });
    this.innerWidth = window.innerWidth;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  logOut() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }
}
