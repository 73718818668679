import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup;
  formGroup2: FormGroup;
  loginProgress: boolean = false;
  forgotPanel: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (
      new Date() < new Date(localStorage.getItem('expire')) &&
      localStorage.getItem('token')
    ) {
      this.router.navigate(['/']);
    }
    this.createForm();
    this.createRecoveryForm();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  createRecoveryForm() {
    this.formGroup2 = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  getError(el) {
    switch (el) {
      case 'email':
        if (this.formGroup.get('email').hasError('required')) {
          return 'Enter your email please!';
        }
        break;
      case 'pass':
        if (this.formGroup.get('password').hasError('required')) {
          return 'Enter your password please!';
        }
        break;
      default:
        return '';
    }
  }

  onSubmit(post) {
    this.loginProgress = true;
    this.authService
      .login(post.email, post.password)
      .toPromise()
      .then((_) => {
        this.loginProgress = false;
        this.router.navigate(['/']);
      })
      .catch((data) => {
        this.loginProgress = false;

        this.toastr.error(undefined, data.error.message, {
          positionClass: 'toast-bottom-center',
          timeOut: 2500,
        });
      });
  }
}
