<div class="bg">

    <div >
      <div class="content-wrapper">
        <div class="content">
          <div class="p-5" *ngIf="!forgotPanel">
            <h2 class="text-center pb-4">Login</h2>
            <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">
              <mat-form-field color="light" appearance="outline" floatLabel="never" class="form-element d-block">
                <mat-label>Email</mat-label>
                <input type="text" matInput formControlName="email" />
                <mat-icon matSuffix fontSet="material-icons-outlined">mail_outline</mat-icon>
                <mat-error *ngIf="formGroup.controls['email'].invalid">
                  {{ getError("email") }}
                </mat-error>
              </mat-form-field>
  
              <mat-form-field floatLabel="never" appearance="outline" class="form-element d-block">
                <mat-label>Password</mat-label>
                <input type="password" matInput formControlName="password" />
                <mat-icon matSuffix fontSet="material-icons-outlined">vpn_key</mat-icon>
                <mat-error *ngIf="!formGroup.controls['password'].valid">
                  {{ getError("pass") }}
                </mat-error>
              </mat-form-field>
  
              <div class="form-element text-center">
                <button *ngIf="!loginProgress" mat-raised-button type="submit" class="button" color="primary"
                  [disabled]="!formGroup.valid">
                  Launch
                </button>
              </div>
              <loading-spinner *ngIf="loginProgress" mode="query"></loading-spinner>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>