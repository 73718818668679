import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  apiBucketUrl = `${environment.url}bucket/`;
  apiFunctionsUrl = `${environment.url}fn-execute/`;
  bucketLicenses: string = '5dd912440566406ec8f0d756';
  bucketUsers: string = '5dd911410566400169f0d755';
  bucketUserOrganizations: string = '5f2c1d64107b9e377a7a0fbc';
  bucketOrganizationInvites: string = '5de51f310566406e5df0d888';

  constructor(private http: HttpClient) {}

  getUserByUserId(id) {
    return this.http.get(
      this.apiBucketUrl +
        this.bucketUsers +
        '/data?relation=true&filter={"usid":"' +
        id +
        '"}'
    );
  }

  getAllProjects() {
    return this.http.get(
      this.apiBucketUrl + this.bucketLicenses + '/data?relation=true'
    );
  }
  getAllUsers() {
    return this.http.get(this.apiBucketUrl + this.bucketUsers + '/data');
  }

  getUserProjects(userid: string) {
    return this.http.get(
      this.apiBucketUrl +
        this.bucketLicenses +
        `/data?relation=true&filter={"$and":[{"user":"${userid}"},{"status":{"$in":["active","requested","denied","expired"]}},{"package_code":{"$nin":["on_premise"]}}]}`
    );
  }

  getUserInvitations(email: string) {
    return this.http.get(
      this.apiBucketUrl +
        this.bucketOrganizationInvites +
        `/data?relation=true&filter={"email":"${email}"}`
    );
  }

  getUserOrganizations(userid: string) {
    return this.http.get(
      this.apiBucketUrl +
        this.bucketUserOrganizations +
        `/data?relation=true&filter={"user._id":"${userid}"}`
    );
  }
}
