<div class="container pt-5 pb-5">
    <loading-spinner *ngIf="!dataPassed"></loading-spinner>

    <div *ngIf="userDetailsObservable | async; let userDataSource">
        <div *ngIf="projects.length > 0">
            <loading-spinner *ngIf="!dataPassed"></loading-spinner>
            <mat-card>
                <mat-card-subtitle>
                    <h5>Projects</h5>
                </mat-card-subtitle>
                <mat-card-content>

                    <table mat-table class="w-100" [dataSource]="projects">

                        <ng-container matColumnDef="project_name">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Package Name
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let project">
                                {{ project.project_name }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="package_code">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Package Code
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let project">
                                {{ project.package_code }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="user_name">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                User
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let project">
                                {{ project.user.name }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Status
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let project">
                                {{ project.status | uppercase }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="expire_date">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Expiry Date
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let project">
                                {{ project.expire_at | date: "mediumDate" }}
                            </td>
                        </ng-container>


                        <tr class="text-center" mat-header-row *matHeaderRowDef="[
            'project_name',
            'package_code',
            'user_name',
            'status',
            'expire_date'

          ]"></tr>
                        <tr mat-row *matRowDef="
            let row;    columns: [ 'project_name', 'package_code','user_name','status','expire_date' ]">
                        </tr>
                    </table>

                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-subtitle>
                    <h5>Servers</h5>
                </mat-card-subtitle>
                <mat-card-content>

                    <table mat-table class="w-100" [dataSource]="projects">

                        <ng-container matColumnDef="server_name">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Server Name
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let project">
                                {{ project.server.name }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ip_address">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Ip Address
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let project">
                                {{ project.server.ip_address }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="version">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Version
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let project">
                                {{ project.server.version}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="state">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                State
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let project">
                                {{ project.server.status}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="created_at">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Created Date
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let project">
                                {{ project.server.created_at | date: "mediumDate" }}
                            </td>
                        </ng-container>


                        <tr class="text-center" mat-header-row *matHeaderRowDef="[
            'server_name',
            'ip_address',
            'version',
            'state',
            'created_at'

          ]"></tr>
                        <tr class="text-center" mat-row *matRowDef="
            let row;
            columns: [
            'server_name',
            'ip_address',
            'version',
            'state',
            'created_at'
            ]
          "></tr>
                    </table>

                </mat-card-content>
            </mat-card>
        </div>
        <div *ngIf="organizations.length > 0">
            <loading-spinner *ngIf="!dataPassed"></loading-spinner>

            <mat-card>
                <mat-card-subtitle>
                    <h5>Organizations</h5>
                </mat-card-subtitle>
                <mat-card-content>

                    <table mat-table class="w-100" [dataSource]="organizations">

                        <ng-container matColumnDef="org_name">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Organization Name
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let organization">
                                {{ organization.organization.name }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="org_role">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Role
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let organization">
                                {{ organization.role }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="user_name">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                User
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let organization">
                                {{ organization.user.name +''+ organization.user.surname }}
                            </td>
                        </ng-container>



                        <tr class="text-center" mat-header-row *matHeaderRowDef="[
            'org_name',
            'org_role',
            'user_name'
          ]"></tr>
                        <tr class="text-center" mat-row *matRowDef="
            let row;
            columns: [
            'org_name',
            'org_role',
            'user_name'
           
            ]
          "></tr>
                    </table>

                </mat-card-content>
            </mat-card>

        </div>
        <div *ngIf="invitations.length > 0">
            <loading-spinner *ngIf="!dataPassed"></loading-spinner>

            <mat-card>
                <mat-card-subtitle>
                    <h5>Invitations</h5>
                </mat-card-subtitle>
                <mat-card-content>

                    <table mat-table class="w-100" [dataSource]="invitations">

                        <ng-container matColumnDef="org_name">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Organization Name
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let invitation">
                                {{ invitation.organization.name }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                E-Mail
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let invitation">
                                {{ invitation.email }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="role">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Role
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let invitation">
                                {{ invitation.role}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="created_at">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                Created Date
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let invitation">
                                {{ invitation.created_at | date: "mediumDate"}}
                            </td>
                        </ng-container>



                        <tr class="text-center" mat-header-row *matHeaderRowDef="[
            'org_name',
            'email',
            'role',
            'created_at'
          ]"></tr>
                        <tr class="text-center" mat-row *matRowDef="
            let row;
            columns: [
            'org_name',
            'email',
            'role',
            'created_at'
            ]
          "></tr>
                    </table>

                </mat-card-content>
            </mat-card>

        </div>
    </div>
</div>