<div class="popup-dialog">
    <div class="row additional-info mt-5">
        <div class="col-12 pb-4">

            <h4 class="w-100 text-center"> See All Informations Of User</h4>

            <form class="example-form">
                <mat-form-field class="w-100 text-center">
                    <input [(ngModel)]="usid" placeholder="Enter User ID" name="user_id" matInput type="text">
                </mat-form-field>
            </form>

        </div>

        <div class="text-right">
            <button mat-button class="btn-choose button-positive" (click)="onOkClick()" cdkFocusInitial
                [disabled]="!usid">
                Ok
            </button>
            <button mat-button class="btn-choose button-negative" (click)="onNoClick()">
                Cancel
            </button>
        </div>
    </div>
</div>