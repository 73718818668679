<mat-sidenav-container fullscreen>
  <div class="header-navigator">
    <a class="logo-container" [routerLink]="['/']">
      <img src="../assets/logo.png" class="logo" />
    </a>

    <div class="container nav-link" *ngIf="innerWidth > 1300 && outlet.activatedRouteData.needSession">
      <a [routerLink]="['/projects']" [class.spica-text-primary]="activeUrl == '/projects'">
        <mat-icon fontSet="material-icons-outlined" class="mr-2">work_outline</mat-icon>Projects
      </a>
      <a [routerLink]="['/users']" [class.spica-text-primary]="activeUrl == '/users'">
        <mat-icon fontSet="material-icons-outlined" class="mr-2">event_note</mat-icon>Users
      </a>
    </div>

    <a class="profile link" [matMenuTriggerFor]="appMenu" *ngIf="outlet.activatedRouteData.needSession">
      {{ 'Profile' }}
      <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>
    </a>

  </div>


  <mat-menu #appMenu="matMenu">
    <div *ngIf="innerWidth <= 1300 && outlet.activatedRouteData.needSession">
      <button mat-menu-item [routerLink]="['/users']">
        <mat-icon fontSet="material-icons-outlined" class="mr-2">people_outline</mat-icon> Users
      </button>
      <button mat-menu-item [routerLink]="['/projects']">
        <mat-icon fontSet="material-icons-outlined" class="mr-2">work</mat-icon> Projects
      </button>
    </div>
    <button *ngIf="loginState" (click)="logOut()" mat-menu-item>
      <mat-icon fontSet="material-icons-outlined">exit_to_app</mat-icon> Logout
    </button>
    <button *ngIf="!loginState" [routerLink]="['/login']" mat-menu-item>
      <mat-icon fontSet="material-icons-outlined">input</mat-icon> Login
    </button>
  </mat-menu>
  <div *ngIf="outlet.activatedRouteData.needSession" class="back-splash"> </div>

  <router-outlet #outlet="outlet"></router-outlet>
</mat-sidenav-container>