<div class="content-area">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="page-title">
                    Users
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <loading-spinner *ngIf="!dataPassed"></loading-spinner>
        <div *ngIf="userObservable | async; let userDataSource">
            <div *ngIf="userDataSource.length == 0 && dataPassed" class="text-center col-12">
                Users Bucket is empty.
            </div>
            <table *ngIf="userDataSource.length > 0 && dataPassed" mat-table class="w-100"
                [dataSource]="userDataSource">
                <ng-container matColumnDef="user_name">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                        Name
                    </th>
                    <td class="text-center" mat-cell *matCellDef="let user">
                        {{ user.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="user_surname">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                        Surname
                    </th>
                    <td class="text-center" mat-cell *matCellDef="let user">
                        {{ user.surname }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                        E-Mail
                    </th>
                    <td class="text-center" mat-cell *matCellDef="let user">
                        {{ user.email }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>
                        Status
                    </th>
                    <td class="text-center" mat-cell *matCellDef="let user">
                      <span class="circle" [style.background]="user.status =='active' ? '#5dff00' : '#ff0000'"></span> 
                      <span>{{ user.status | uppercase }}</span> 
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>Action</th>
                    <td class="text-center" mat-cell *matCellDef="let user">
                        <button mat-icon-button (click)="openDialog(user._id)">
                            <mat-icon fontSet="material-icons-outlined">build_outline</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr class="text-center" mat-header-row *matHeaderRowDef="[
            'user_name',
            'user_surname',
            'email',
            'status',
            'actions'
          ]"></tr>
                <tr class="text-center" mat-row *matRowDef="
            let row;
            columns: [
            'user_name',
            'user_surname',
            'email',
            'status',
            'actions'
            ]
          "></tr>
            </table>
        </div>
    </div>
</div>